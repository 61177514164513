import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Cloudflare",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/cloudflare.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Cloudflare`}</h1>
    <p>{`Integrate Crisp to your site in one-click`}</p>
    <hr></hr>
    <p>{`Cloudflare is a CDN platform supercharging your website. Cloudflare also provides an app-store, allowing you to add third-party widgets (like Crisp) to your website in a single click.`}</p>
    <h2>{`Benefits of using Crisp and Cloudflare:`}</h2>
    <ul>
      <li parentName="ul">{`Add the Crisp Livechat in a single click`}</li>
      <li parentName="ul">{`Manage all your conversations from the Crisp Inbox`}</li>
      <li parentName="ul">{`Automatically adapts to your user language (50+ locales are supported)`}</li>
      <li parentName="ul">{`Customize the Crisp livechat to fit your design`}</li>
      <li parentName="ul">{`Load your website's live chat instantly`}</li>
      <li parentName="ul">{`Available on every plan with Crisp`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      